* {
  font-family: "Noto Sans CJK KR";
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 100;
  src: url("assets/styles/fonts/NotoSansKR-Light.woff2") format("woff2"),
    url("assets/styles/fonts/NotoSansKR-Light.woff") format("woff"),
    url("assets/styles/fonts/NotoSansKR-Light.otf") format("truetype");
}

@font-face {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: normal;
  src: url("assets/styles/fonts/NotoSansKR-Regular.woff2") format("woff2"),
    url("assets/styles/fonts/NotoSansKR-Regular.woff") format("woff"),
    url("assets/styles/fonts/NotoSansKR-Regular.otf") format("truetype");
}

@font-face {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 500;
  src: url("assets/styles/fonts/NotoSansKR-Medium.woff2") format("woff2"),
    url("assets/styles/fonts/NotoSansKR-Medium.woff") format("woff"),
    url("assets/styles/fonts/NotoSansKR-Medium.otf") format("truetype");
}

@font-face {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: bold;
  src: url("assets/styles/fonts/NotoSansKR-Bold.woff2") format("woff2"),
    url("assets/styles/fonts/NotoSansKR-Bold.woff") format("woff"),
    url("assets/styles/fonts/NotoSansKR-Bold.otf") format("truetype");
}
